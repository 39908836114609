import React,{ useState, useEffect } from "react"
import firebase from 'firebase'
import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

const Documents = () => {
    const location = useLocation();
    const buildingId = location.pathname.split("/")[2]
    const [BuildingDocuments, setBuildingDocuments] = useState("")
    const [loadingFlag, setLoadingFlag] = useState(false)
    const [newDocuments, setNewDocuments] = useState({})
    const [targetDocumentName, setTargetDocumentName] = useState("")
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    var storage = firebase.storage();

    useEffect(()=>{
        getBuildingDocuments()
    }, [])

    async function getBuildingDocuments(){
      setLoadingFlag(true)
      let result = []
        await storage.ref().child(`${buildingId}/documents/`)
        .listAll().then((res) => {
          console.log(res,"res")
          res.items.forEach(async (itemRef) => {
            await storage.ref().child(itemRef.location.path)
            .getDownloadURL().then(async (url) => {
                result.push({name: itemRef.name, url: url})
            })
          });
        }).catch((error) => {
          // Uh-oh, an error occurred!
        });

        await delay(2)
        setBuildingDocuments(result)
        setLoadingFlag(false)
    }

    function delay(n){
      return new Promise(function(resolve){
          setTimeout(resolve,n*1000);
      });
  }

  const uploadDocuments = async () => {
    for(let key in newDocuments){
        if(typeof newDocuments[key] === "object"){
          storage.ref(`${buildingId}/documents/${newDocuments[key].name}`).put(newDocuments[key]).then((snapshot) => {
            console.log("success");
          })
        }
     
    }
  }

  const uploadZumenTemplate = async () => {
    for(let key in newDocuments){
        if(typeof newDocuments[key] === "object"){
          storage.ref(`pontintTemplateSheet.xlsx`).put(newDocuments[key]).then((snapshot) => {
            console.log("success");
          })
        }
     
    }
    alert("図面原紙を更新しました。")
  }

  console.log(newDocuments, "newDocuments")

  const deleteTargetDocument = async () => {
    console.log(targetDocumentName, "targetDocumentName")
    const targetDocRef = storage.ref().child(`${buildingId}/documents/${targetDocumentName}`);
    targetDocRef.delete().then(() => {
        console.log("sccess")
      }).catch((error) => {
        // Uh-oh, an error occurred!
      });
  }

  const handleChange = (e) => {
    setNewDocuments(e.target.files)
  }

  return(
   <>
   {loadingFlag  ? <ReactLoading type="bubbles" color="#A9A9A9" height={"5%"} width={"80%"} />: 
    <div>
      <div style={{textAlign: "left", padding: "10px 5%"}}>
        <div style={{marginTop: 30, textAlign: "right"}}>
          <button onClick={()=>{setOpen3(true)}}>図面原紙変更</button>
        </div>
        <h3 style={{borderLeft: "medium solid #299fd6", marginBottom: 10}}>　建物資料</h3> 
        
        {BuildingDocuments.length>0 ?
        <>
          {BuildingDocuments.map((doc, i)=>{
            return(
              <div key={i} style={{marginTop:10}}>
                <a href={doc.url} target="_blank" rel="noopener noreferrer">{doc.name}</a>
                <HighlightOffIcon onClick={()=>{
                  setTargetDocumentName(doc.name)
                  setOpen2(true)}
                  } style={{color: "red", marginLeft: 10,fontSize: 15}}/>
              </div>
            )
          })}
          <div style={{marginTop: 30, textAlign: "center"}}>
          <button onClick={()=>{setOpen(true)}}>+</button>
          </div>
        </>:
          <p>提出頂いた資料はありません。</p> 
        }
 
      </div> 
      <Dialog fullWidth onClose={()=>{setOpen(false)}}  open={open}>
      
      <div style={{textAlign: "center", margin: "15px"}}>
      <p>アップロードするファイルを追加してください。</p>  
      <div style={{marginBottom: 50}}>
        <input onChange={handleChange}　multiple type="file" accept="image/*,.pdf,.xlsx"></input>  
      </div>  
      <Button onClick={async ()=> {
        uploadDocuments()
        setLoadingFlag(true)
        await delay(1)
        getBuildingDocuments()
        setOpen(false)
        }} style={{width: "200px", color: "#fff", backgroundColor: "#115293"}}>追加</Button>
      </div>
    </Dialog> 
    <Dialog fullWidth onClose={()=>{setOpen2(false)}}  open={open2}>
      
      <div style={{textAlign: "center", margin: "15px"}}>
      <p>選択したファイルを削除しますか？</p>  
      <p>{targetDocumentName}</p>
      <Button onClick={()=> {
        setOpen2(false)
        setTargetDocumentName("")
      }} style={{width: "100px", color: "#fff", backgroundColor: "#115293", marginRight: 9}}>いいえ</Button>
      <Button onClick={async ()=> {
        deleteTargetDocument()
        setLoadingFlag(true)
        await delay(1)
        getBuildingDocuments()
        setTargetDocumentName("")
        setOpen2(false)
        }} style={{width: "100px", color: "#fff", backgroundColor: "#115293"}}>はい</Button>
      </div>
    </Dialog> 
    <Dialog fullWidth onClose={()=>{setOpen3(false)}}  open={open3}>
      
      <div style={{textAlign: "center", margin: "15px"}}>
      <p>図面原紙をアップロードしてください。<br/><span style={{fontSize: 6}}>※ 変更後はすべての作業者がダウンロードできる図面が更新されます。</span>  </p>
      <div style={{marginBottom: 50}}>
        <input onChange={handleChange}　multiple type="file" accept="image/*,.pdf,.xlsx"></input>  
      </div>  
      <Button onClick={async ()=> {
        uploadZumenTemplate()
        setOpen3(false)
        }} style={{width: "200px", color: "#fff", backgroundColor: "#115293"}}>更新</Button>
      </div>
    </Dialog> 
    </div>}

   </>   
  )  
}

export default Documents;