import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import firebase from 'firebase'
import 'firebase/firestore'
import 'firebase/functions'
import polyglotI18nProvider from 'ra-i18n-polyglot';
import japaneseMessages from '@bicstone/ra-language-japanese';
import { BuildingList, BuildingCreate, BuildingEdit } from "./buildings";
import { WorkerList, WorkerCreate, WorkerEdit } from "./workers";
import { ClientList, ClientCreate, ClientEdit } from "./clients";
import LoginPage from './LoginPage';
import MyLayout from './MyLayout';
import MyMenu from './MyMenu';
import customRoutes from "./customRoutes";
import {
  FirebaseDataProvider,
  FirebaseAuthProvider,
} from 'react-admin-firebase';
import './App.css';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  databaseURL: process.env.REACT_APP_FIREBASE_DB,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const firebaseApp = firebase.initializeApp(config);

const authProvider = FirebaseAuthProvider(config);

export const db = firebase.firestore();

export const functions = firebase.functions();

const customAuthProvider = {
  ...authProvider,
  login: async ({ email, password }) => {
    await firebase.auth().signInWithEmailAndPassword(email, password)
  }
}

const dataProvider = FirebaseDataProvider(config, {
  logging: true,
  // rootRef: 'rootrefcollection/QQG2McwjR2Bohi9OwQzP',
  app: firebaseApp,
  // watch: ['posts'];
  // dontwatch: ['comments'];
  persistence: 'local',
  // disableMeta: true
  dontAddIdFieldToDoc: true,
  lazyLoading: {
    enabled: true,
  },
  firestoreCostsLogger: {
    enabled: true,
  },
});

const i18nProvider = polyglotI18nProvider(() => japaneseMessages, 'ja', { allowMissing: true});

function App() {
  return (
    <Admin menu={MyMenu} customRoutes={customRoutes}  layout={MyLayout} i18nProvider={i18nProvider} loginPage={LoginPage}  authProvider={customAuthProvider} dataProvider={dataProvider}>
        <Resource name="buildings" list={BuildingList} create={BuildingCreate} edit={BuildingEdit}/>
        <Resource name="workers" list={WorkerList} create={WorkerCreate} edit={WorkerEdit}/>
        <Resource name="clients" list={ClientList} create={ClientCreate} edit={ClientEdit}/>
    </Admin>
  );
}

export default App;
