import * as React from "react";
import { Route } from 'react-router-dom';
import PDF from "./PDF"
import Documents from "./Documents"

const customRoutes = [
    <Route exact path="/buildings/:buildingId/pdf" component={PDF} />,
    <Route exact path="/buildings/:buildingId/documents" component={Documents} />,
];

export default customRoutes