import * as React from 'react';
import { useState } from 'react';
import { useLogin } from 'react-admin';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const login = useLogin();
    return (
        <div>
        <p style={{
            textAlign: "center",
            marginTop: 100,
            marginBottom: 50,
            color: "#1A1A1A",
            fontWeight: "bold"
          }}>全住診 管理画面</p>
          <div style={{textAlign: "center"}}> 
          <TextField 
          style={{
            width:300,
            height: 30,
            marginBottom: 40,
            textAlign: "center"
          }}
          label="メールアドレス" variant="outlined" type="email" required onChange={(e) => setEmail(e.target.value)}
          />

          <div style={{textAlign: "center"}}>
          <TextField
          style={{
            width:300,
            height: 30,
            marginBottom: 40
          }}
          label="パスワード" variant="outlined" type="password" required onChange={(e) => setPassword(e.target.value)}
          />
          
          </div>
        </div>
        
        <div style={{textAlign: "center"}}>
        <Button
          style={{
            background: "#0E5C93",
            color: "#fff",
            width:300,
            height: 45,
            marginTop: 40,
            marginBottom: 3
          }}
          onClick={()=>{
              login({email, password})
              .catch((err) => {
                console.log(err)
                alert("メールアドレスまたはパスワードが違います。")
              }
              )}
          }
        >
          ログイン
        </Button>
        </div>
    </div>
    );
};

export default LoginPage;