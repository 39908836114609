import * as React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink, Menu } from 'react-admin';
import { withRouter } from 'react-router-dom';

import HomeWorkIcon from '@material-ui/icons/HomeWork';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';


const MyMenu = ({ onMenuClick, logout }) => {
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    //左側のメニューに追加したいときは以下に追加していく。
    return (
        <div style={{marginTop: 25}}>
            <Menu>
            <MenuItemLink
                to="/buildings?filter=%7B%7D"
                primaryText="物件情報一覧"
                leftIcon={<HomeWorkIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to="/clients"
                primaryText="顧客情報一覧"
                leftIcon={<AssignmentIndIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to="/workers"
                primaryText="検査員一覧"
                leftIcon={<AssignmentIndIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            {isXSmall && logout}
            </Menu>
        </div>
    );
}

export default withRouter(MyMenu);