import * as React from "react";
import { db , functions } from "./App.js"
import {
    List,
    Datagrid,
    TextField,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    BooleanInput,
    useNotify,
    useGetOne
} from 'react-admin';
import Button from '@material-ui/core/Button';

const Empty = () => {
  return (
      <div style={{color: "#C0C0C0"}}>
          <p>現在該当する検査員はいません。</p>
      </div>
  );
};


export const WorkerList = props => {
  const [accountStatus, setAccountStatus] = React.useState("申請中")
  return(
    <>
    <div style={{marginTop: 30, fontWeight: "bold"}}>検査員情報<span>【{accountStatus}】</span></div>
    <div style={{marginTop: 20}}>
    <Button style={{ background: "#0E5C93",color: "#fff", marginRight: 5}} onClick={()=>{setAccountStatus("申請中")}}>申請中</Button>
    <Button style={{ background: "#0E5C93",color: "#fff", marginRight: 5}} onClick={()=>{setAccountStatus("承認済み")}}>承認済み</Button>
    </div>
    <div style={{marginTop: 30}}></div>

    {accountStatus==="申請中" &&
    <List　empty={<Empty />} actions={false} filter={{ isAccountCreated: false}} bulkActionButtons={false} {...props}>
       <Datagrid rowClick="edit"> 
        <TextField source="name" label="名前"/>
        <TextField source="phoneNumber" label="電話番号"/>
        <TextField source="mailAddress" label="メールアドレス"/>
      </Datagrid>
    </List>
    }

    {accountStatus==="承認済み" &&
    <List　empty={<Empty />} actions={false} filter={{ isAccountCreated: true}} bulkActionButtons={false} {...props}>
       <Datagrid rowClick="edit"> 
        <TextField source="name" label="名前"/>
        <TextField source="phoneNumber" label="電話番号"/>
        <TextField source="mailAddress" label="メールアドレス"/>
      </Datagrid>
    </List>
    }
    </>
)};

export const WorkerCreate = props => {

    return(
    <Create {...props}>
      <SimpleForm>  
         <TextInput source="name" label="名前"/> 
         <TextInput source="name-katakana" label="名前（カタカナ）"/> 
         <TextInput source="phone" label="電話番号"/> 
         <TextInput source="mailAddress" label="メールアドレス"/> 
      </SimpleForm>
     </Create>
 )};


export const WorkerEdit = props => {
  const notify = useNotify();

  const onSuccess = async ({data}) => {
    console.log("送信！！")
    console.log(data, "data")
    if(data?.isAccountCreated){
      const createUser = functions.httpsCallable('createUser');
      createUser({ email: data.mailAddress, password: "password", lineId: data.lineId})
          .then((result) => {
            console.log(result, "result")
          }).catch(e => {
            console.log(e, "error");
          });
    }

    notify(`更新しました。`);      
    }
    

  const handleAssign = (e) => {
    // e "true or false"
    if(e){
      alert("保存を押すとアカウントが作成されます。")
    }
  }

  const { data, loaded } = useGetOne('workers', props.id);
  
  return(
    <Edit  onSuccess={onSuccess} undoable={false} {...props}>
        <SimpleForm>
          <TextField source="id" label="検査員ID"/> 
          <TextField source="name" label="名前"/> 
          <TextField source="nameKana" label="名前（カタカナ）"/> 
          <TextField source="phoneNumber" label="電話番号"/> 
          <TextField source="mailAddress" label="メールアドレス"/> 
          <TextField source="skill" label="保有資格"/> 
          <TextField source="lineId" label="ラインID" />

          <br/><br/>
          {data?.isAccountCreated === false && <BooleanInput onChange={handleAssign} source="isAccountCreated" label="アカウント作成"/> }
        </SimpleForm>
    </Edit>
)};