import firebase from "firebase";
import { db , functions } from "./App.js"
import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    AutocompleteInput,
    DateTimeInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
    ReferenceField,
    ImageInput,
    ImageField,
    UrlField,
    TopToolbar,
    CreateButton,
    ArrayInput, 
    SimpleFormIterator,
    useNotify,
    useRefresh,
    useGetOne,
    BooleanInput,
    ArrayField,
    BooleanField,
    FunctionField,
    FileInput,
    FileField
} from 'react-admin';
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';


const Empty = () => {
    return (
        <div style={{color: "#C0C0C0"}}>
            <p>現在該当する物件はありません</p>
        </div>
    );
};

export const BuildingList = props => {

    const [viewname, setNameView] = React.useState("検査依頼")
    
    return (
    <>
    <div style={{marginTop: 30}}>
    <div style={{fontWeight: "bold"}}>{viewname}</div>
    <div style={{marginTop: 20}}></div>
    <Button style={{ background: "#0E5C93",color: "#fff", marginRight: 5}} onClick={()=>{setNameView("検査依頼")}}>検査依頼</Button>
    <Button style={{ background: "#0E5C93",color: "#fff", marginRight: 5}} onClick={()=>{setNameView("未検査")}}>未検査</Button>
    <Button style={{ background: "#0E5C93",color: "#fff", marginRight: 5}} onClick={()=>{setNameView("検査済み")}}>検査済み</Button>
    <Button style={{ background: "#0E5C93",color: "#fff", marginRight: 5}} onClick={()=>{setNameView("検査結果報告済み")}}>検査結果報告済み</Button>
    <div style={{marginBottom: 20}}></div>
    </div>
    {viewname === "検査依頼" &&
    <>
    <List empty={<Empty />} actions={false} filter={{ isAssigned: false}}  bulkActionButtons={false} {...props}>
       <Datagrid rowClick="edit"> 
       <TextField source="address" label="所在地住所" sortable={false} />
       <ReferenceField source="clientId" reference="clients" label="依頼主" sortable={false}>
          <TextField source="name" label="名前" />
        </ReferenceField> 
        <FunctionField label="ステータス" render={record => record.asignStatus==="該当なし" ? `🔴 ${record.asignStatus}` : `🟡 ${record.asignStatus}`} />
        {/*<DateField source="inspectionDate" label="検査希望日" sortable={false}/>
        <ReferenceField source="workerId" reference="workers" label="担当者" sortable={false} >
            <TextField source="name" label="担当者"/>
        </ReferenceField> >*/}
      </Datagrid>
    </List>
    </>
    }

    {viewname === "未検査" &&
    <>
    <List empty={<Empty />} actions={false} filter={{ isFinished: false , isAssigned: true}}  bulkActionButtons={false} {...props}>
       <Datagrid rowClick="edit"> 
       <ImageField source="buildingPicture.src" label="物件画像" sortable={false} />
       <TextField source="address" label="所在地住所" sortable={false} />
        <DateField source="inspectionDateTime.inspectionDateTime1" label="検査予定日" sortable={false}/> 
        <ReferenceField source="workerId" reference="workers" label="担当者" sortable={false}>
          <TextField source="name" label="担当者" />
        </ReferenceField> 
      </Datagrid>
    </List>
    </>
    }

    {viewname === "検査済み" &&
    <>
    <List empty={<Empty />} actions={false} filter={{ isFinished: true, isAssigned: true, isReportSent: false}} bulkActionButtons={false} {...props}>
    <Datagrid rowClick="edit"> 
    <ImageField source="buildingPicture.src" label="物件画像" sortable={false} />
    <TextField source="address" label="所在地住所" sortable={false} />
    <ReferenceField source="clientId" reference="clients" label="依頼主" sortable={false}>
          <TextField source="name" label="名前" />
        </ReferenceField> 
     <ReferenceField source="workerId" reference="workers" label="担当者" sortable={false} >
       <TextField source="name" label="担当者"/>
     </ReferenceField> 
     </Datagrid>
    </List>
    </>
    }
    {viewname === "検査結果報告済み" &&
    <>
    <List empty={<Empty />} actions={false} filter={{ isFinished: true, isAssigned: true, isFinished: true, isReportSent: true}} bulkActionButtons={false} {...props}>
    <Datagrid rowClick="edit"> 
    <ImageField source="buildingPicture.src" label="物件画像" sortable={false} />
    <TextField source="address" label="所在地住所" sortable={false} />
    <ReferenceField source="clientId" reference="clients" label="依頼主" sortable={false}>
          <TextField source="name" label="名前" />
        </ReferenceField>
    <TextField source="paymentStatus" label="入金ステータス" sortable={false} />   
     </Datagrid>
    </List>
    </>}
    </>
)};

export const BuildingCreate = props => {
    return(
    <Create {...props}>  
      <SimpleForm>   
         <AutocompleteInput source="type" label="種別" choices={[
            { id: '新築', name: '新築' },
            { id: '中古', name: '中古' },
         ]}/>
         <AutocompleteInput source="buildingType" label="建物種類" choices={[
            { id: '一戸建て', name: '一戸建て' },
            { id: 'マンション', name: 'マンション' },
            { id: '古民家', name: '古民家' },
         ]}/> 

         <ImageInput source="buildingPicture" label="物件画像" accept="image/*" placeholder={<p>検査物件の写真をここにアップロードしてください。</p>}>
           <ImageField source="src"/>
        </ImageInput> 

         <NumberInput source="postCode" label="所在地郵便番号"/> 
         <TextInput source="address" label="所在地住所"/> 
         <TextInput source="ageOfBuilding" label="対象物件の築年数"/> 
         {/*<DateTimeInput source="inspectionDate" label="検査希望日と時間"/> */}
         <AutocompleteInput source="howToReceive" label="報告書の受け取り方" choices={[
            { id: 'PDF（こちらは無料）', name: 'PDF（こちらは無料）' },
            { id: '郵送（宅配便）※有料3,240円', name: '郵送（宅配便）※有料3,240円' },
         ]}/> 

         <ImageInput source="pictures" label="図面" accept="image/*" placeholder={<p>検査物件全体の図面をアップロードしてください。</p>}>
           <ImageField source="src"/>
         </ImageInput>
         <p style={{fontSize: "14px", width:"100%"}}>* 調査報告にて図面ポインティングをする際に使用されます。</p>

         <BooleanInput source="isFinished" label="" disabled defaultValue={false} style={{display:"none"}}/>
      </SimpleForm>
     </Create>
 )};


export const BuildingEdit = props => {
    const { data, loaded } = useGetOne('buildings', props.id);
    //const [isAssigned, setIsAssigned] = React.useState(false)
    const [formTypeInInspecutionDone, setFormTypeInInspecutionDone] =  React.useState("nomal")
    console.log(data, "data1")

    const handleAssign = (e) => {
      // e "true or false"
      if(e){
        //setIsAssigned(true)
        alert("担当者と検査予定日時をもう一度ご確認ください。保存を押すと担当者アサインが確定し、依頼主にLINE通知を送信します。")
      }else {
      //setIsAssigned(false)
      }
    }

    const handleChange = (e) => {
      if(e){
        //setIsAssigned(true)
        alert("添付した報告書と請求金額に問題がないか確認してください。保存を押すと、依頼主のLINEに送信されます。")
      }else {
      //setIsAssigned(false)
      }
    }

    const notify = useNotify();
    const refresh = useRefresh();

    const onSuccess = async ({data}) => {
        //await delay(3)
         //console.log(isAssigned, "isAssigned")
        //if(data.isAssigned===true && data.workerId && data.asignStatus==="該当なし"){
          console.log("送信！！")
          console.log(data, "data")
          console.log(data.inspectionDateTime, "data.inspectionDateTime")
          if(data.isAssigned===true){
          const sendAssignMessage = functions.httpsCallable('sendAssignMessage');
          sendAssignMessage({ workerId: data.workerId, clientId: data.clientId, buildingId: props.id })
            .then((result) => {
              console.log(result, "result")
            }).catch(e => {
              console.log(e, "error");
            });
          }
        //}
        notify(`更新しました。`);
        //refresh();
    };

    const onSuccess2 = async ({data}) => {
        console.log(data, "data")
        if(data.isFinished ===true && data.isReportSent===true){
        const sendReport = functions.httpsCallable('sendReport');
        sendReport({ workerId: data.workerId, clientId: data.clientId, buildingId: props.id })
          .then((result) => {
            console.log(result, "result")
          }).catch(e => {
            console.log(e, "error");
          });
        }
      //}
      notify(`更新しました。`);
      //refresh();
  };

    return(
    <> 
    {!data?.isAssigned && !data?.isFinished && // 検査依頼（アサイン未完了で検査が未完了）
    <Edit onSuccess={onSuccess} undoable={false} {...props}>
        <SimpleForm>
        <p style={{fontWeight: "bold"}}>基本情報</p>
        <TextField source="id" label="物件ID"/> 
        <ReferenceInput source="clientId" reference="clients" label="依頼主">
           <SelectInput source="name" label="依頼主"/>
        </ReferenceInput> 
        
        <TextInput source="postCode" label="所在地郵便番号"/> 
            <TextInput source="address" label="所在地住所"/> 
            <TextInput source="ageOfBuilding" label="対象物件の築年数"/> 

            <AutocompleteInput source="type" label="種別" choices={[
                { id: '新築（建売）', name: '新築（建売）' },
                { id: '新築（注文）', name: '新築（注文）' },
                { id: '中古', name: '中古' },
            ]}/>
            <AutocompleteInput source="buildingType" label="建物種類" choices={[
                { id: '一戸建て', name: '一戸建て' },
                { id: '共同住宅(1部屋)', name: '共同住宅(1部屋)' },
                { id: '共同住宅(全棟)', name: '共同住宅(全棟)' },
            ]}/> 

            <TextInput source="floorArea" label="床面積（㎡）"/>
            
            <AutocompleteInput source="howToReceive" label="報告書の受け取り方" choices={[
            { id: 'PDF（こちらは無料）', name: 'PDF（こちらは無料）' },
            { id: '郵送（宅配便）※有料3,240円', name: '郵送（宅配便）※有料3,240円' },
            ]}/>  
            
            <div style={{marginBottom: 20}}>
            <Link to={`/buildings/${props.id}/documents`} style={{textAlign: "right", cursor: "pointer"}}>建物資料</Link>
            </div>

            <p style={{marginTop: 30, fontWeight: "bold"}}>検査希望日時</p>
            <DateTimeInput source={"inspectionDateTime.inspectionDateTime1"} label="第1希望日時" disabled/> 
            {data?.hasOwnProperty("inspectionDateTime") && data.inspectionDateTime?.inspectionDateTime2 && <DateTimeInput source={"inspectionDateTime.inspectionDateTime2"} label="第2希望日時" disabled/> }
            {data?.hasOwnProperty("inspectionDateTime") && data.inspectionDateTime?.inspectionDateTime3 && <DateTimeInput source={"inspectionDateTime.inspectionDateTime3"} label="第3希望日時" disabled/> }

            <p style={{marginTop: 20, fontWeight: "bold"}}>LINE送信ログ</p>
            {data?.hasOwnProperty("lineMessageLog") ?
            <ArrayField source="lineMessageLog" label="">
                <Datagrid>
                    <ReferenceField source="workerId" reference="workers" label="検査員名">
                        <TextField source="name" label="依頼主"/>
                    </ReferenceField> 
                    <BooleanField source="isReplied" label="回答の有無"/>
                </Datagrid>
            </ArrayField>:<p style={{color: "#C0C0C0"}}>送信のログはありません。</p>}

            <p style={{marginTop: 30, fontWeight: "bold"}}>アサイン情報</p>

            <DateTimeInput source="inspectionDateTime.inspectionDateTime1" label="検査予定日時"/>

            <ReferenceInput source="workerId" reference="workers" label="担当者">
              <SelectInput optionText="name"/>
            </ReferenceInput> 

            <BooleanInput onChange={handleAssign} source="isAssigned" label="アサイン確定"/>


           <div style={{marginTop: 50}}></div>
        </SimpleForm>
    </Edit>}

    {data?.isAssigned && !data?.isFinished && // 未検査（アサイン済みで検査が未完了）
    <Edit undoable={false} {...props}>
        <SimpleForm>
        <p style={{fontWeight: "bold"}}>基本情報</p>
        <TextField source="id" label="物件ID"/> 
        <ReferenceInput source="clientId" reference="clients" label="依頼主">
           <SelectInput source="name" label="依頼主"/>
        </ReferenceInput> 
        
        <TextInput source="postCode" label="所在地郵便番号"/> 
            <TextInput source="address" label="所在地住所"/> 
            <TextInput source="ageOfBuilding" label="対象物件の築年数"/> 

            <AutocompleteInput source="type" label="種別" choices={[
                { id: '新築（建売）', name: '新築（建売）' },
                { id: '新築（注文）', name: '新築（注文）' },
                { id: '中古', name: '中古' },
            ]}/>
            <AutocompleteInput source="buildingType" label="建物種類" choices={[
                { id: '一戸建て', name: '一戸建て' },
                { id: '共同住宅(1部屋)', name: '共同住宅(1部屋)' },
                { id: '共同住宅(全棟)', name: '共同住宅(全棟)' },
            ]}/> 
            <TextInput source="floorArea" label="床面積（㎡）"/>
            
            <AutocompleteInput source="howToReceive" label="報告書の受け取り方" choices={[
            { id: 'PDF（こちらは無料）', name: 'PDF（こちらは無料）' },
            { id: '郵送（宅配便）※有料3,240円', name: '郵送（宅配便）※有料3,240円' },
            ]}/>  
            
            <div style={{marginBottom: 20}}>
            <Link to={`/buildings/${props.id}/documents`} style={{textAlign: "right", cursor: "pointer"}}>建物資料</Link>
            </div>

            <DateTimeInput source="inspectionDateTime.inspectionDateTime1" label="検査予定日時"/>

            {data?.isAssigned &&
            <>

            <ReferenceInput source="workerId" reference="workers" label="担当者">
              <SelectInput optionText="name"/>
            </ReferenceInput>

            <p style={{fontWeight: "bold"}}>図面設定</p>

            <ImageInput source="buildingPicture" label="物件画像" accept="image/*" placeholder={<p>検査物件の写真をここにアップロードしてください。</p>}>
               <ImageField source="src" label="物件画像" title="title"/>
             </ImageInput> 
             <p style={{fontSize: "14px"}}>* 報告書の表紙などに使用されます。</p>


            <ImageInput source="pictures" label="図面（検査物件全体）" accept="image/*" placeholder={<p>検査物件全体の図面をアップロードしてください。</p>}>
                <ImageField source="src" label="商品画像" title="title"/> 
            </ImageInput> 
    
            <p style={{fontSize: "14px", width:"100%"}}>* 調査報告にて図面ポインティングをする際に使用されます。</p>

            <br />
            <br />

            <p style={{fontWeight: "bold"}}>検査場所設定</p>
            <ArrayInput source="places1F" label="検査場所 1階">
                <div style={{margin: 30}}>
                  <ImageInput source="floorImage1F" label="図面" accept="image/*" placeholder={<p>検査場所の図面をアップロードしてください。</p>}>
                        <ImageField source="src" label="図面画像" title="title"/> 
                  </ImageInput> 
                  <p style={{fontSize: "14px"}}>* 撮影時の案内として使用されます。</p>
                <SimpleFormIterator>
                    <TextInput source="placeName" label="検査場所名"/>
                    <div style={{display: "none"}}><TextField defaultValue={"test"} source="id" label="id"/></div>
                </SimpleFormIterator>
                </div>
            </ArrayInput>

            <ArrayInput source="places2F" label="検査場所 2階">
                <div style={{margin: 30}}>
                  <ImageInput source="floorImage2F" label="図面" accept="image/*" placeholder={<p>検査場所の図面をアップロードしてください。</p>}>
                        <ImageField source="src" label="図面画像" title="title"/> 
                  </ImageInput> 
                  <p style={{fontSize: "14px"}}>* 撮影時の案内として使用されます。</p>
                <SimpleFormIterator>
                    <TextInput source="placeName" label="検査場所名"/>
                </SimpleFormIterator>
                </div>
            </ArrayInput>

            <ArrayInput source="places3F" label="検査場所 3階">
                 <div style={{margin: 30}}>
                  <ImageInput source="floorImage3F" label="図面" accept="image/*" placeholder={<p>検査場所の図面をアップロードしてください。</p>}>
                        <ImageField source="src" label="図面画像" title="title"/> 
                  </ImageInput> 
                  <p style={{fontSize: "14px"}}>* 撮影時の案内として使用されます。</p>
                <SimpleFormIterator>
                    <TextInput source="placeName" label="検査場所名"/>
                </SimpleFormIterator>
                </div>
            </ArrayInput>

            <BooleanInput source="isFinished" label="検査完了"/>
            
           </>}
           <div style={{marginTop: 50}}></div>
        </SimpleForm>
    </Edit>}

    {data?.isFinished && !data?.isReportSent　&& formTypeInInspecutionDone === "nomal" && //検査完了
    <Edit {...props}>
      
        <SimpleForm>
        <div style={{textAlign: "left"}}>  
        <button style={{border: "none", background: "transparent", color: "blue"}}  onClick={()=>{setFormTypeInInspecutionDone("report")}}>▶︎ 検査結果報告へ</button>
      </div>
        
        <p style={{fontWeight: "bold"}}>基本情報</p>
        <TextField source="id" label="物件ID"/> 
        <ReferenceInput source="clientId" reference="clients" label="依頼主">
           <SelectInput source="name" label="依頼主"/>
        </ReferenceInput> 
        
        <TextInput source="postCode" label="所在地郵便番号"/> 
            <TextInput source="address" label="所在地住所"/> 
            <TextInput source="ageOfBuilding" label="対象物件の築年数"/> 

            <AutocompleteInput source="type" label="種別" choices={[
                { id: '新築（建売）', name: '新築（建売）' },
                { id: '新築（注文）', name: '新築（注文）' },
                { id: '中古', name: '中古' },
            ]}/>
            <AutocompleteInput source="buildingType" label="建物種類" choices={[
                { id: '一戸建て', name: '一戸建て' },
                { id: '共同住宅(1部屋)', name: '共同住宅(1部屋)' },
                { id: '共同住宅(全棟)', name: '共同住宅(全棟)' },
            ]}/> 
            <TextInput source="floorArea" label="床面積（㎡）"/>
            
            <AutocompleteInput source="howToReceive" label="報告書の受け取り方" choices={[
            { id: 'PDF（こちらは無料）', name: 'PDF（こちらは無料）' },
            { id: '郵送（宅配便）※有料3,240円', name: '郵送（宅配便）※有料3,240円' },
            ]}/>  
            
            <div style={{marginBottom: 20}}>
            <Link to={`/buildings/${props.id}/documents`} style={{textAlign: "right", cursor: "pointer"}}>建物資料</Link>
            </div>

            <DateTimeInput source="inspectionDateTime.inspectionDateTime1" label="検査予定日時"/>

            {data?.isAssigned &&
            <>

            <ReferenceInput source="workerId" reference="workers" label="担当者">
              <SelectInput optionText="name"/>
            </ReferenceInput>

            <p style={{fontWeight: "bold"}}>図面設定</p>

            <ImageInput source="buildingPicture" label="物件画像" accept="image/*" placeholder={<p>検査物件の写真をここにアップロードしてください。</p>}>
               <ImageField source="src" label="物件画像" title="title"/>
             </ImageInput> 
             <p style={{fontSize: "14px"}}>* 報告書の表紙などに使用されます。</p>


            <ImageInput source="pictures" label="図面（検査物件全体）" accept="image/*" placeholder={<p>検査物件全体の図面をアップロードしてください。</p>}>
                <ImageField source="src" label="商品画像" title="title"/> 
            </ImageInput> 
    
            <p style={{fontSize: "14px", width:"100%"}}>* 調査報告にて図面ポインティングをする際に使用されます。</p>

            <br />
            <br />

            <p style={{fontWeight: "bold"}}>検査場所設定</p>
            <ArrayInput source="places1F" label="検査場所 1階">
                <div style={{margin: 30}}>
                  <ImageInput source="floorImage1F" label="図面" accept="image/*" placeholder={<p>検査場所の図面をアップロードしてください。</p>}>
                        <ImageField source="src" label="図面画像" title="title"/> 
                  </ImageInput> 
                  <p style={{fontSize: "14px"}}>* 撮影時の案内として使用されます。</p>
                <SimpleFormIterator>
                    <TextInput source="placeName" label="検査場所名"/>
                    <div style={{display: "none"}}><TextField defaultValue={"test"} source="id" label="id"/></div>
                </SimpleFormIterator>
                </div>
            </ArrayInput>

            <ArrayInput source="places2F" label="検査場所 2階">
                <div style={{margin: 30}}>
                  <ImageInput source="floorImage2F" label="図面" accept="image/*" placeholder={<p>検査場所の図面をアップロードしてください。</p>}>
                        <ImageField source="src" label="図面画像" title="title"/> 
                  </ImageInput> 
                  <p style={{fontSize: "14px"}}>* 撮影時の案内として使用されます。</p>
                <SimpleFormIterator>
                    <TextInput source="placeName" label="検査場所名"/>
                </SimpleFormIterator>
                </div>
            </ArrayInput>

            <ArrayInput source="places3F" label="検査場所 3階">
                 <div style={{margin: 30}}>
                  <ImageInput source="floorImage3F" label="図面" accept="image/*" placeholder={<p>検査場所の図面をアップロードしてください。</p>}>
                        <ImageField source="src" label="図面画像" title="title"/> 
                  </ImageInput> 
                  <p style={{fontSize: "14px"}}>* 撮影時の案内として使用されます。</p>
                <SimpleFormIterator>
                    <TextInput source="placeName" label="検査場所名"/>
                </SimpleFormIterator>
                </div>
            </ArrayInput>

            {<Link to={`/buildings/${props.id}/pdf`}>検査結果</Link> }

            <div style={{marginBottom: 20}}></div>

            <BooleanInput source="isFinished" label="検査完了"/>
            
           </>}
           <div style={{marginTop: 50}}></div>
        </SimpleForm> 
    </Edit>}

    {data?.isFinished && !data?.isReportSent　&& formTypeInInspecutionDone === "report" && //検査完了
    <Edit onSuccess={onSuccess2} undoable={false} {...props}>
        <SimpleForm>
        <div style={{textAlign: "left"}}>  
        <button style={{border: "none", background: "transparent", color: "blue"}}  onClick={()=>{setFormTypeInInspecutionDone("nomal")}}>◀︎ 戻る</button>
        </div>
        <p style={{fontWeight: "bold"}}>基本情報</p>

        <TextField source="id" label="物件ID"/> 

        <ReferenceInput source="clientId" reference="clients" label="依頼主">
           <SelectInput source="name" label="依頼主"/>
        </ReferenceInput> 


        <TextInput source="buildingType" label="建物種類"/>       
        <TextInput source="floorArea" label="床面積（㎡）"/>      
        <NumberInput source="price" label="請求金額"/>   


        <div style={{marginBottom: 20}}></div>

        <FileInput source="report" label="報告書" accept="application/pdf" placeholder={<p>作成した報告書をアップロードしてください。</p>}>
            <FileField source="src" label="報告書" title="title"/> 
        </FileInput>

        <div style={{marginBottom: 20}}></div>

        <BooleanInput onChange={handleChange} source="isReportSent" label="検査報告完了"/>

           <div style={{marginTop: 50}}></div>
        </SimpleForm>
    </Edit>}

    {data?.isReportSent && //検査報告完了          
    <Edit undoable={false} {...props}>
        <SimpleForm>
        <p style={{fontWeight: "bold"}}>基本情報</p>

        <TextField source="id" label="物件ID"/> 

        <ReferenceInput source="clientId" reference="clients" label="依頼主">
           <SelectInput source="name" label="依頼主"/>
        </ReferenceInput> 
        
        <TextField source="postCode" label="所在地郵便番号"/> 
        <TextField source="address" label="所在地住所"/> 

        <TextField source="buildingType" label="建物種類" />      
        <TextField source="floorArea" label="床面積"/> 

        <ReferenceInput source="workerId" reference="workers" label="担当者">
            <SelectInput optionText="name"/>
        </ReferenceInput> 

        <div style={{marginBottom: 20}}>
            <Link to={`/buildings/${props.id}/documents`} style={{textAlign: "right", cursor: "pointer"}}>建物資料</Link>
        </div>

        {<a href={data?.report.src} target="_blank" rel="noopener noreferrer">報告書</a> }
        <div style={{marginBottom: 20}}></div>

        <div style={{marginBottom: 20}}></div>

        <p style={{fontWeight: "bold"}}>決済情報</p>

        <AutocompleteInput source="paymentStatus" label="入金ステータス"  choices={[
            { id: '未確認', name: '未確認' },
            { id: '完了', name: '完了' },
            { id: '要対応', name: '要対応' },
            ]}/> 

        <TextInput source="price" label="料金"/> 

        <TextInput source="paidPrice" label="支払額"/> 

           <div style={{marginTop: 50}}></div>
        </SimpleForm>
    </Edit>}
    </>   
    )};