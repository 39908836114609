import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    TopToolbar,
    CreateButton
} from 'react-admin';

const ListActions = (props) => (
  <TopToolbar>
      <CreateButton label="登録"/>
  </TopToolbar>
);

export const ClientList = props => (
  <>
    <div style={{marginTop: 30, fontWeight: "bold"}}>顧客情報</div>
    <List actions={<ListActions/>} bulkActionButtons={false}  {...props}>
       <Datagrid rowClick="edit"> 
        <TextField source="name" label="名前"/>
        <TextField source="phone" label="電話番号"/>
        <TextField source="mailAddress" label="メールアドレス"/>
      </Datagrid>
    </List>
  </>
);

export const ClientCreate = props => {

    return(
    <Create {...props}>
      <SimpleForm>  
         <TextInput source="name" label="名前"/> 
         <TextInput source="nameKana" label="名前（カタカナ）"/> 
         <TextInput source="phone" label="電話番号"/> 
         <TextInput source="mailAddress" label="メールアドレス"/> 
         <TextInput source="address" label="住所"/> 
      </SimpleForm>
     </Create>
 )};


export const ClientEdit = props => (
    <Edit {...props}>
        <SimpleForm>
          <TextField source="id" label="顧客ID"/> 
          <TextInput source="name" label="名前"/> 
          <TextInput source="nameKana" label="名前（カタカナ）"/> 
          <TextInput source="phone" label="電話番号"/> 
          <TextInput source="mailAddress" label="メールアドレス"/> 
          <TextInput source="postCode" label="郵便番号"/> 
          <TextInput source="address" label="住所"/> 
          <TextField source="lineId" label="ラインID"/> 
        </SimpleForm>
    </Edit>
);