// in src/MyAppBar.js
import * as React from 'react';
import { AppBar } from 'react-admin';
import Logo from "./zenjushin-logo.png"


const MyAppBar = props => {
    return (
        <AppBar {...props} style={{ color: "black", backgroundColor: "#fff" }}>    
            <span style={{flex: 1}}/>    
            <img src={Logo} height="50"/> 
            <span style={{flex: 1}}/>  
        </AppBar>
    );
};

export default MyAppBar;