import React, { useEffect, useState } from 'react'
import { db } from "./App"
import { ref, getDownloadURL } from "firebase/storage";
import firebase from 'firebase'
import 'firebase/firestore'
import { useLocation } from 'react-router-dom';
import { myFont } from './utils/mplus'
import inspectionDevice  from "./inspectionDevice.png"
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ReactLoading from 'react-loading';
import 'jspdf-autotable'
import TopImg from "./sample.png" //後で変更
import ZenjushinLogoImg from "./zenjushi-logo.png"
import { numToBranchNum } from './utils/numToBranchNum';

const { jsPDF } = require("jspdf");

const PDF = () => {
    const location = useLocation();
    const buildingId = location.pathname.split("/")[2]
    const [data, setData] = useState([])
    const [problemList, setProblemList] = useState([])
    const [isEditing, setIsEditing] = useState(false)
    const [contentEdited, setContentEdited] = useState([]);
    const [place, setPlace] = useState("gaibukiso")
    const [loadingFlag, setLoadingFlag] = useState(false)
    const [type, setType] = useState("problem")
    const [satsueiPictures, setSatsueiPictures] = useState([])
    const [customPlaces, setCustomPlaces] = useState([])

    console.log(satsueiPictures, "satsueiPictures")

    console.log(problemList, "problemList")

    console.log(data, "DATA")

    const style = {
      background: "#0E5C93",
      color: "#fff",
      fontSize: "16px",
      fontWeight: 70,
      borderRadius: "8px",
      marginRight: 5,
      marginTop: 5,
      cursor: "pointer"
    }

    const problemCheckList = 
    [{name: "外部基礎", id: "gaibukiso"}, 
     {name: "外壁", id: "gaiheki"}, 
     {name: "軒まわり", id: "nokimawari"},
     {name: "屋根", id: "yane"},
     {name: "バルコニー ", id: "balcony"},
     {name: "敷地外周", id: "shikichigaishuu"},
     {name: "敷地（建物周囲）", id: "shikichi"},
     {name: "工作物", id: "kousakubutsu"},
     {name: "給湯機設備", id: "kyuuyukisetsubi"},
     {name: "室内床", id: "yuka"},
     {name: "室内壁", id: "kabe"},
     {name: "室内天井", id: "tenjou"},
     {name: "玄関", id: "genkan"},
     {name: "室内建具", id: "shitsunaitategu"},
     {name: "外壁側サッシ", id: "gaihekigawassashi"},
     {name: "設置物等", id: "setsubibutsu"},
     {name: "小屋裏", id: "koyaura"},
     {name: "各階間天井裏・下屋小屋裏", id: "tenjouurakoyaura"},
     {name: "床下基礎立ちあがり及び土間", id: "yukashitakiso"},
     {name: "床下配管", id: "yukashitahaikan"},
     {name: "床組、土間等", id: "yukagumidoma"},
     {name: "給水", id: "kyuusui"},
     {name: "給湯設備", id: "kyuuyusetsubi"},
     {name: "排水", id: "haisui"},
     {name: "換気設備", id: "kankisetsubi"},
    ]

    const satsueiPlaceList = 
    [{name: "全景", id: "zenkei"}, 
     {name: "屋根", id: "yane"}, 
     {name: "外周部", id: "gaishubu"},
     {name: "基礎", id: "kiso"},
     {name: "外壁", id: "gaiheki"},
     {name: "閉口部", id: "heikoubu"},
     {name: "軒まわり", id: "nokimawari"},
     {name: "隣地境界", id: "rinchikyoukai"},
     {name: "機器類", id: "kikirui"},
     {name: "測定調査", id: "sokuteichousa"},
    ]

    function getUidByProblemName(name){
      if(name==="外部基礎"){
        return "a"
      }else if(name==="外壁"){
        return "b"
      }else if(name==="軒まわり"){
        return "c"
      }else if(name==="屋根"){
        return "d"
      }else if(name==="バルコニー "){
        return "e"
      }else if(name==="敷地外周"){
        return "f"
      }else if(name==="敷地（建物周囲）"){
        return "g"
      }else if(name==="工作物"){
        return "h"
      }else if(name==="給湯機設備"){
        return "i"
      }else if(name==="床" || name==="室内床"){
        return "j"
      }else if(name==="壁"|| name==="室内壁"){
        return "k"
      }else if(name==="天井"|| name==="室内天井"){
        return "l"
      }else if(name==="玄関"){
        return "m"
      }else if(name==="室内建具"){
        return "n"
      }else if(name==="外壁側サッシ"){
        return "o"
      }else if(name==="設置物等"){
        return "p"
      }else if(name==="小屋裏"){
        return "q"
      }else if(name==="各階間天井裏・下屋小屋裏"){
        return "r"
      }else if(name==="床下基礎立ちあがり及び土間"){
        return "s"
      }else if(name==="床下配管"){
        return "t"
      }else if(name==="床組、土間等"){
        return "u"
      }else if(name==="給水"){
        return "v"
      }else if(name==="給湯設備"){
        return "w"
      }else if(name==="排水"){
        return "x"
      }else if(name==="換気設備"){
        return "y"
      }else{
        return "z"
      }
    }

    var storage = firebase.storage();

    //const zenkeiImages, setZenkeiImages = useState([])
   
    /*
    var storage = firebase.storage();
    const ref = storage.ref().child(`inspectionDevice.png`).getDownloadURL()
    .then((url) => {
      // Do something with the URL ...
      console.log(url , "url")
    })
    */

   useEffect(()=>{

     if(buildingId){
       console.log(buildingId, "buildingId")
      if(place==="all"){
        getProblemsData()
        return
      } 
      getData()
     }  
    },[buildingId,place]) 

    async function getData(){
      console.log("called")
     await db.collection("buildings").doc(buildingId).collection("details").doc("inspection2").collection(place).orderBy("order", "asc").get()
     .then((querySnapshot) => {
       let result = []
       querySnapshot.forEach((doc) => {
           result.push({...doc.data(), id : doc.id})
       });
       setData(result)
   });
   }
   

  function delay(n){
      return new Promise(function(resolve){
          setTimeout(resolve,n*1000);
      });
  }

  async function getAllData(){
    console.log("getAllData called")
    let result = []
    let hasProblemLists = []
    let hasProblemListsResult = []
   problemCheckList.forEach(async (item)=>{
     console.log(item.id)
     await db.collection("buildings").doc(buildingId).collection("details").doc("inspection2").collection(item.id).orderBy("order", "asc").get()
   .then((querySnapshot) => {
    
    querySnapshot.forEach(async (doc) => {
       console.log(doc.data(), "doc.data()")
       result.push(doc.data())
       //let placeHolder = []
       if(doc.data()?.hasProblem==="あり"){
        console.log("HAS PROBLEM")
        hasProblemLists.push(doc.data().problems)
        if(doc.data().problems.length){
          doc.data().problems.forEach(async (problem, i)=>{
            if(problem.imgUrl){
              let blob
              console.log(problem.imgUrl, "problem.imgUrl")
              const xhr= new XMLHttpRequest();
              xhr.responseType= 'blob';
              
              xhr.open('GET', problem.imgUrl);
              xhr.send();
          
              xhr.onload = (event)=> {
                blob = xhr.response;
              };
              
              await delay(30)
              console.log(blob, "blob")
              // 一つの項目に複数ある場合枝番をつける
              if(doc.data().problems.length>1){
                hasProblemListsResult.push({pointingId: doc.data().pointingId,title: doc.data().title,subtitle: doc.data().subtitle ,checItem: doc.data().checItem, imgData:blob, comment: problem.comment, branchId: numToBranchNum(i+1)})
              }else {
                hasProblemListsResult.push({pointingId: doc.data().pointingId,title: doc.data().title,subtitle: doc.data().subtitle ,checItem: doc.data().checItem, imgData:blob, comment: problem.comment, branchId: ""})
              }
              }
          })
          //hasProblemListsResult[doc.data().pointingId]=placeHolder
        }
       }
       
     });
   }) 
 })
 await delay(100)
 const data = result.map((com) => [com.title, com.subtitle, getUidByProblemName(com.title)+String(com.order), com.checItem, com.hasProblem==="あり" ? "●" : "", com.hasProblem==="本物件に該当しない" ? "-" : "●"])
 console.log(data, "data")
 console.log(hasProblemListsResult, "hasProblemListsResult")
 //setProblemList(hasProblemLists)
 return {data, hasProblemListsResult}
}

async function getProblemsData(){
  setLoadingFlag(true)
  console.log("getAllData called")
  let hasProblemLists = []
  let hasProblemListsResult = []
 problemCheckList.forEach(async (item)=>{
   console.log(item.id)
   await db.collection("buildings").doc(buildingId).collection("details").doc("inspection2").collection(item.id).orderBy("order", "asc").get()
 .then((querySnapshot) => {
  
  querySnapshot.forEach(async (doc) => {
     if(doc.data()?.hasProblem==="あり"){
      console.log("HAS PROBLEM")
      hasProblemLists.push(doc.data().problems)
      if(doc.data().problems.length){
        doc.data().problems.forEach(async (problem)=>{
          if(problem.imgUrl){
            hasProblemListsResult.push(doc.data())
            }
        })
      }
     }
   });
 }) 
})
console.log(hasProblemListsResult, "hasProblemListsResult")
setData(hasProblemListsResult)
await delay(3)
setLoadingFlag(false)
}

async function getImageTest(satsueiPlace){
  //let result = []
  let result = {}

  console.log(satsueiPlace, "satsueiPlace")

  satsueiPlace.forEach(async (place)=>{
    let placeHolder = []
    console.log(place, "place")
    let pictureNum = 4
    if(place==="sokuteichousa"){
      pictureNum = 6
    }
    for(let i=1; i<=pictureNum; i++){
      console.log(place, i, "placeとi")
    await storage.ref().child(`${buildingId}/${place}/${i}`).getDownloadURL()
  .then(async (url) => {
    if(url){
    let blob
    console.log(url, "url")
    const xhr= new XMLHttpRequest();
    xhr.responseType= 'blob';
    
    xhr.open('GET', url);
    xhr.send();

    xhr.onload = (event)=> {
      blob = xhr.response;
    };
    
    await delay(30)
    //when the blob is returned
    //imgList.map(img=> img.src= URL.creatObjectURL(blob));
    console.log(blob, "blob")
    placeHolder.push({place: place,imgData:blob})
    }
  }).catch(err => {
    // Here you can handle the error for individual download
    console.log(err.message, "err")
  })
  }
    //result.push({placeName: place, imgData: placeHolder})
    result[place] = placeHolder
  })
  await delay(200)
  console.log(result, "blob result")
  return result
}

async function getCustomPlaceImg () {
  let result = {}
  await db.collection("buildings").doc(buildingId).get()
  .then((doc)=>{
    console.log(doc.data(), "doc.data()")
    //setTargetBuildingData(doc.data())
    //console.log(doc.data().places, "aaa")
    const places1F = doc.data().places1F
    const places2F = doc.data().places2F
    const places3F = doc.data().places3F
    const placesArr = [places1F,places2F,places3F]
    console.log(placesArr, "placesArr")
    let places = []
    placesArr.forEach((place)=>{
      if(place){
        places = [...places,...place]
      }
    })
  
    console.log(places, "placesplaces")

    if(places.length>0){
      console.log("CALLED")
      places.forEach((place, i)=>{
        let placeHolder = []
        if(place.hasOwnProperty("imageUrl")){
        place.imageUrl.forEach(async (url)=>{
          
          if(url){
            let blob
            console.log(url, "url")
            const xhr= new XMLHttpRequest();
            xhr.responseType= 'blob';
            
            xhr.open('GET', url);
            xhr.send();
        
            xhr.onload = (event)=> {
              blob = xhr.response;
            };
            
            await delay(40)
  
            console.log(blob, "blob")
            placeHolder.push({placeName: place.placeName,imgData:blob, id: place.id})
        }
        })}
        result[i+1] = placeHolder
      })
      
    }  
  
  })

  await delay(200)
  console.log(result, "result test" )
  return result
  
}

async function storageListAll(){
  var storageRef = firebase.storage().ref();
  var listRef = storageRef.child(`${buildingId}`);
  let listResult = []
listRef.listAll()
  .then((res) => {    
    res.prefixes.forEach((folderRef) => {
      const placeName = folderRef.location.path.split("/")[1]
      if(placeName!=="reports"){
        listResult.push(placeName)
      }
    });
  }).catch((error) => {
    // Uh-oh, an error occurred!
    console.log(error, "error")
  });
  console.log(listResult, "listResult")
    return listResult
}

const getSatsueiPictures = async () => {
  setLoadingFlag(true)
  let result = {}
  console.log(satsueiPlaceList)
  satsueiPlaceList.forEach(async (place)=>{
          let placeHolder = []
          console.log(place.id, "place")
          let pictureNum = 4
          if(place.id==="sokuteichousa"){
            pictureNum = 6
          }
          for(let i=1; i<=pictureNum; i++){
            console.log(place.id, i, "placeとi")
          await storage.ref().child(`${buildingId}/${place.id}/${i}`).getDownloadURL()
        .then(async (url) => {
          if(url){
     
          console.log(url, "url")
          
          placeHolder.push({place: place,imgUrl : url})
          }
        }).catch(err => {
          // Here you can handle the error for individual download
          console.log(err.message, "err")
        })
        }
          //result.push({placeName: place, imgData: placeHolder})
          result[place.id] = placeHolder
        })      

        await db.collection("buildings").doc(buildingId).get()
        .then((doc)=>{
          console.log(doc.data(), "doc.data()")
          //setTargetBuildingData(doc.data())
          //console.log(doc.data().places, "aaa")
          const places1F = doc.data().places1F
          const places2F = doc.data().places2F
          const places3F = doc.data().places3F
          const placesArr = [places1F,places2F,places3F]
          console.log(placesArr, "placesArr")
          let places = []
          placesArr.forEach((place)=>{
            if(place){
              places = [...places,...place]
            }
          })
          console.log(places, "placesplaces")
          setCustomPlaces(places)
        })
  
    




        await delay(5)
        console.log(result, "blob result")
        setSatsueiPictures(result)
        //const customData = await getCustomPlaceImg()
        setLoadingFlag(false)

}

const handleInputChange = (e) => {
  const name = e.target.name
  console.log(name, "e.target.name")

  //console.log(data, "data")
  const selectedContent = data.find(obj=>obj.id===e.target.id)
  if(name==="hasProblem"){
    selectedContent.hasProblem = e.target.value
  }else if(name.includes("comment")){
    const index = name[name.length-1]
    console.log(index, "index")
    selectedContent.problems[index].comment = e.target.value
  }


  let obj = {
    id: e.target.id,
    ...selectedContent
  }

  // 編集履歴なしのときは新しく追加
  if(!contentEdited.find(obj=> Object.values(obj).includes(e.target.id))){
    setContentEdited([...contentEdited, obj])
  } else { // 編集履歴があるときは上書き
    const targetIndex = contentEdited.findIndex(obj=>obj.id===e.target.id)
    let newObj = {...selectedContent}
    Object.assign(contentEdited[targetIndex],newObj);
    setContentEdited(contentEdited)
  }
}

const updateInspectionData = () => {
  contentEdited.forEach(async (content)=>{
    await db.collection("buildings").doc(buildingId).collection("details").doc("inspection2").collection(place).doc(content.id).set(content, {merge: true})
  })
}

    async function pdfPrint(data, hasProblemListsResult, testBlob, customData){
        console.log(data, "input data in pdfPrint")
        console.log(testBlob, "input data in pdfPrint")
        console.log(customData, "input data in pdfPrint")
        console.log(hasProblemListsResult,  "input data in pdfPrint hasProblemListsResult")

        console.log(Object.keys(customData).length, "Object.keys(customData).length,")
        const buildingData = await db.collection("buildings").doc(buildingId).get()
        .then((doc)=>{
          return doc.data()
        })

        const assignedWorkerData = await db.collection("workers").doc(buildingData.workerId).get()
        .then((doc)=>{
          return doc.data()
        })

        const buildingImg = await storage.ref().child(`${buildingId}/building-top/1`).getDownloadURL()
        .then(async (url) => {
          if(url){
          let blob
          console.log(url, "url")
          const xhr= new XMLHttpRequest();
          xhr.responseType= 'blob';
          
          xhr.open('GET', url);
          xhr.send();

          xhr.onload = (event)=> {
            blob = xhr.response;
          };
          
          await delay(30)
          //when the blob is returned
          //imgList.map(img=> img.src= URL.creatObjectURL(blob));
          console.log(blob, "blob")
          return blob
          }
        }).catch(err => {
          // Here you can handle the error for individual download
          console.log(err.message, "err")
        })

        const pointingImg = await storage.ref().child(`${buildingId}/reports/poitingImg`).getDownloadURL()
        .then(async (url) => {
          if(url){
          let blob
          console.log(url, "url")
          const xhr= new XMLHttpRequest();
          xhr.responseType= 'blob';
          
          xhr.open('GET', url);
          xhr.send();

          xhr.onload = (event)=> {
            blob = xhr.response;
          };
          
          await delay(30)
          //when the blob is returned
          //imgList.map(img=> img.src= URL.creatObjectURL(blob));
          console.log(blob, "blob")
          return blob
          }
        }).catch(err => {
          // Here you can handle the error for individual download
          console.log(err.message, "err")
        })

        const levelPoitingImg = await storage.ref().child(`${buildingId}/reports/levelPoitingImg`).getDownloadURL()
        .then(async (url) => {
          if(url){
          let blob
          console.log(url, "url")
          const xhr= new XMLHttpRequest();
          xhr.responseType= 'blob';
          
          xhr.open('GET', url);
          xhr.send();

          xhr.onload = (event)=> {
            blob = xhr.response;
          };
          
          await delay(30)
          //when the blob is returned
          //imgList.map(img=> img.src= URL.creatObjectURL(blob));
          console.log(blob, "blob")
          return blob
          }
        }).catch(err => {
          // Here you can handle the error for individual download
          console.log(err.message, "err")
        })

        console.log(buildingData, "buildingData")
            const doc = new jsPDF({ 
              orientation: 'p',
              format: 'a4',
            });

            // 日本語を設定
            doc.addFileToVFS('mplus.ttf', myFont);
            doc.addFont('mplus.ttf', 'mplus', 'normal');
            doc.setFont('mplus', 'normal'); 
            const d = doc.getFontList();
            const width = doc.internal.pageSize.width;
            const height = doc.internal.pageSize.height;

            function insertFooter(){
              const pageCount = doc.internal.getNumberOfPages()
              for(let i=1; i<=pageCount; i++){
                doc.setPage(i)
                doc.setFontSize(9)
                doc.text(width/2-1,292, String(i));
              }
            }

            doc.setFontSize(12);
            
            const tatemonogaibu1 = []
            const tatemonogaibu2 = []
            const tatemonoshuui = []
            const shitsunai1 = []
            const shitsunai2 = []
            const yaneura = []
            const yukashita = []
            const setsubi = []

            let currentSubtitle = ""
            for(const row of data){
              if(row[0] === "外部基礎" || row[0] === "外壁"){
                if(row[2]==="a1" || row[2]==="b1"){
                  tatemonogaibu1.push(row)
                  currentSubtitle = row[1]
                } else{
                  row[0]=""  
                  if(row[1]===currentSubtitle){
                    row[1]=""
                  } else {
                    currentSubtitle = row[1]
                  }
                  tatemonogaibu1.push(row)
                }
              } else if(row[0] === "軒まわり" || row[0] === "屋根" || row[0] === "バルコニー "){
                if(row[2]==="c1" || row[2]==="d1" || row[2]==="e1" ){
                  tatemonogaibu2.push(row)
                  currentSubtitle = row[1]
                } else{
                  row[0]=""  
                  if(row[1]===currentSubtitle){
                    row[1]=""
                  } else {
                    currentSubtitle = row[1]
                  }
                  tatemonogaibu2.push(row)
                }
              } else if(row[0] === "敷地外周" || row[0] === "敷地（建物周囲）" || row[0] === "工作物" || row[0] === "給湯機設備"){
                if(row[2]==="f1" || row[2]==="g1" || row[2]==="h1" || row[2]==="i1"){
                  tatemonoshuui.push(row)
                  currentSubtitle = row[1]
                } else{
                  row[0]=""  
                  if(row[1]===currentSubtitle){
                    row[1]=""
                  } else {
                    currentSubtitle = row[1]
                  }
                  tatemonoshuui.push(row)
                }
              } else if(row[0] === "床" || row[0] === "室内床" || row[0] === "壁" || row[0] === "室内壁" || row[0] === "天井" || row[0] === "室内天井" || row[0] === "玄関"){
                if(row[2]==="j1" || row[2]==="k1" || row[2]==="l1" || row[2]==="m1"){
                  shitsunai1.push(row)
                  currentSubtitle = row[1]
                } else{
                  row[0]=""  
                  if(row[1]===currentSubtitle){
                    row[1]=""
                  } else {
                    currentSubtitle = row[1]
                  }
                  shitsunai1.push(row)
                }
              } else if(row[0] === "室内建具" || row[0] === "外壁側サッシ" || row[0] === "設置物等"){
                if(row[2]==="n1" || row[2]==="o1" || row[2]==="p1"){
                  shitsunai2.push(row)
                  currentSubtitle = row[1]
                } else{
                  row[0]=""  
                  if(row[1]===currentSubtitle){
                    row[1]=""
                  } else {
                    currentSubtitle = row[1]
                  }
                  shitsunai2.push(row)
                }
              } else if(row[0] === "小屋裏" || row[0] === "各階間天井裏・下屋小屋裏"){
                if(row[2]==="q1" || row[2]==="r1"){
                  yaneura.push(row)
                  currentSubtitle = row[1]
                } else{
                  row[0]=""  
                  if(row[1]===currentSubtitle){
                    row[1]=""
                  } else {
                    currentSubtitle = row[1]
                  }
                  yaneura.push(row)
                }
              } else if(row[0] === "床下基礎立ちあがり及び土間" || row[0] === "床下配管"|| row[0] === "床組、土間等"){
                if(row[2]==="s1" || row[2]==="t1" || row[2]==="u1"){
                  yukashita.push(row)
                  currentSubtitle = row[1]
                } else{
                  row[0]=""  
                  if(row[1]===currentSubtitle){
                    row[1]=""
                  } else {
                    currentSubtitle = row[1]
                  }
                  yukashita.push(row)
                }
              } else if(row[0] === "給水" || row[0] === "給湯設備" || row[0] === "排水" || row[0] === "換気設備"){
                if(row[2]==="v1" || row[2]==="w1" || row[2]==="x1" || row[2]==="y1"){
                  setsubi.push(row)
                  currentSubtitle = row[1]
                } else{
                  row[0]=""  
                  if(row[1]===currentSubtitle){
                    row[1]=""
                  } else {
                    currentSubtitle = row[1]
                  }
                  setsubi.push(row)
                }
              }
            }

            let type = ""
            if(buildingData["type"]==="中古"){
              type = "既存"
            }else if(buildingData["type"]==="新築（建売）"){
              type = "新築"
            }

            let buildingType = ""
            if(buildingData["buildingType"]==="一戸建て"){
              buildingType = "一戸建て"
            }else if(buildingData["buildingType"].includes("共同住宅")){
              type = "共同住宅"
            }

            doc.autoTable({
              margin: { top: 30, right: 40, left: 40 },
              body: [[`${type}${buildingType}インスペクション \n 調査報告書`]],
              styles: {font: 'mplus', fontStyle: 'normal', fontSize: 24, textColor: "black", lineColor: "black", lineWidth: 0.4},
              theme: 'grid',
              columnStyles: { 0: { halign: 'center', valign: "middle" }}
            })
            
            doc.addImage(buildingImg ? URL.createObjectURL(buildingImg): TopImg, 'PNG', 40, 80, width-40*2, 0)

            doc.addImage(ZenjushinLogoImg, 'PNG', 40, 200, width-40*2, 0); 

           
          doc.addPage()  
           
          doc.autoTable({
            margin: { top: 30, right: 30, left: 30 , bottom: 50},
            body: [["物件概要"]],
            styles: {font: 'mplus', fontStyle: 'normal', fontSize: 20, textColor: "black", lineColor: "black", lineWidth: 0.4},
            theme: 'grid',
            columnStyles: { 0: { halign: 'center', valign: "middle" }}
          })

           doc.autoTable({
            startY: 60,
            margin: { top: 100, right: 30, left: 30, bottom: 50 },
            body: [["所在地", `〒${buildingData.postCode} 	\n ${buildingData.address}`], ["構造種別", buildingData["structure"]], ["延べ床面積", `${buildingData["floorArea"]}㎡`]],
            styles: {font: 'mplus', fontStyle: 'normal', fontSize: 12, textColor: "black", lineColor: "black", lineWidth: 0.4},
            theme: 'grid',
            columnStyles: { 0: { halign: 'center', valign: "middle", cellWidth: 40 }, 1: { halign: 'center' } }
          })

          doc.autoTable({
            startY: 100,
            margin: { top: 200, right: 30, left: 30 , bottom: 50},
            body: [["ご依頼者様", `${buildingData.clientName}　様`], ["検査時間", buildingData.inspectionDate ? `${buildingData.inspectionDate}`: ""], ["天候", buildingData.weather ? `${buildingData.weather}`: ""]],
            styles: {font: 'mplus', fontStyle: 'normal', fontSize: 12, textColor: "black", lineColor: "black", lineWidth: 0.4},
            theme: 'grid',
            columnStyles: { 0: { halign: 'center', valign: "middle", cellWidth: 40 }, 1: { halign: 'center' } }
          })

          doc.autoTable({
            startY: 150,
            margin: { right: 30, left: 30, bottom: 50 },
            body: [["検査法人", "一般社団法人全国住宅診断促進協会 \n 〒100-0011東京都千代田区内幸町1-3-1 \n 幸ビルディング9階 \n TEL:03-6823-3490"], ["検査担当者", assignedWorkerData.name], ["保有資格", assignedWorkerData.skill.join(' \n ')], ["建築士事務所", assignedWorkerData.belongs]],
            styles: {font: 'mplus', fontStyle: 'normal', fontSize: 12, textColor: "black", lineColor: "black", lineWidth: 0.4},
            theme: 'grid',
            columnStyles: { 0: { halign: 'center', valign: "middle", cellWidth: 40 }, 1: { halign: 'center' } }
          })

            doc.addPage()

            doc.text("検査について（1）", 90, 30)

            doc.text("●検査の目的", 10, 50);
            doc.text("検査の目的は、検査により補修を要する程度の事象が認められたか否かの検査を行い、ご依頼者様に", 10, 60)
            doc.text("報告することです。", 10, 65)

            doc.text("●検査の方法", 10, 75)
            doc.text("当日の状況について検査員が歩行できる方法で目視、触診などの非破壊による検査を行なっています。", 10, 85)

            doc.text("●検査項目について", 10, 100)
            doc.text("各項目毎の検査範囲は抽出検査となるため、全数検査は行っていません。また、建築基準法等の各種", 10, 110)
            doc.text("法律に適合しているか否かの確認や耐震診断は行っていません。", 10, 115)

            doc.text("●検査基準について", 10, 130)
            doc.text("各々の部分・部材を目視、触診等により検査を行い、当社の検査基準に基づき報告しています。", 10, 140)

            doc.text("●検査結果について", 10, 155)
            doc.text("当日の目視可能な範囲にて検査した結果を記載しております。したがいまして、全ての範囲確認でき", 10, 165)
            doc.text("いない項目でも結果表示がされます。目視できていない箇所に関しましては、本報告書と相違する場", 10, 170)
            doc.text("合があります。", 10, 175)

            doc.text("●資料について", 10, 190)
            doc.text("関係者から提供された資料や聞き取り調査による情報の誤りに起因し発生する損害について当社は責", 10, 200)
            doc.text("任を負いかねます。", 10, 205)

            doc.text("●その他注意事項", 10, 220)
            doc.text("本報告書は、検査当日の状態を記載しています。本報告書に記載されている状態が、検査後も継続す", 10, 230)
            doc.text("ることを保証するものではありません。", 10, 235)
            doc.text("本報告書に関する質問はご依頼者様からのみ受け付けております。ご依頼者様以外の方からのご質問", 10, 245)
            doc.text("にはお答えいたしません。", 10, 250)
            doc.text("ご依頼者様の同意なく、本報告書を弊社から第三者へお渡しすることはありません。ご依頼者様が本", 10, 260)
            doc.text("報告書を第三者に渡すことにより、不利益が生じた場合は、弊社は責を負いません。", 10, 265)

            doc.addPage()

            doc.text("検査機器について（一例）", 80, 30)
            doc.addImage(inspectionDevice, 'PNG', 10, 50, width-30, 0); 
            doc.text("※オプション検査器具も記載されています。検査器具はサンプルです。", 10, 110); 
            doc.text("画像と違う場合もありますが性能は同一です。", 10, 115)

            doc.text("報告書について", 85, 140)

            doc.text("「あり」の欄に●チェックがある場合、補修や精密診断が必要なことを示します。",10,155)
            doc.text("「 済 」の欄に●チェックがある場合、目視可能な範囲において検査したことを示します。",10,160)
            doc.text("「 済 」の欄にーチェックがある場合、その部位が本物件に該当しない、見れなかったことを",10,165)
            doc.text("表します。",10,170)

            //doc.addImage(testImg, 'jpg', 10, 50, width-30, 0); 

            doc.addPage()

            doc.text("検査について（２）", 90, 30)

            doc.text("●なぜ、建物外部の検査が必要なのか",10, 50)
            doc.text("外壁は美観上だけでなく、耐朽性上も重要な部分です。施工不良・件年劣化等があると、壁内に雨水", 10, 60)
            doc.text("が侵入し、構造材にダメージを与えます。外壁の隙間等から雨漏りを起こさないように使用されて", 10, 65)
            doc.text("いるシーリング材は防水性にとって重要になります。", 10, 70)
            doc.text("また、軒裏では、雨漏り等の状況がないかを確認します。", 10, 75)
            doc.text("バルコニーに関しては防水性が非常に重要となります。防水材の経年劣化や不具合が無いかを検査し", 10, 80)
            doc.text("ます。", 10, 85)

            doc.text("●なぜ、建物周囲の検査が必要なのか", 10, 100)
            doc.text("塀やフェンス、擁壁は、ひび割れ等があると、耐久性に問題が出るのでひび割れ等の検査をします。", 10, 110)
            doc.text("地盤沈下で建物が傾くと修繕に多額の費用が掛かります。地盤に陥没がないか等を見ることも重要に", 10, 115)
            doc.text("なります。", 10, 120)

            doc.text("●なぜ、室内の検査が必要なのか", 10, 135)
            doc.text("建物が傾いている場合、大きな問題となります。その傾きの判断を、ビー玉や感覚だけで行うには",10,145)
            doc.text("限界があります。建物に傾きがあるかどうかは、機材できっちり計測することが重要となります。",10,150)
            doc.text("室内の建具は調整が行われていないとメンテナンスが必要となるので確認することが重要です。", 10,155)
            doc.text("また、設置物は固定が行われているかの確認が必要です。", 10,160)

            doc.text("●なぜ、屋根裏の検査が必要なのか", 10, 175)
            doc.text("住宅トラブルで多い項目のひとつに雨漏りがあります。雨漏りの被害は放っておくと建物に大きな",10,185)
            doc.text("ダメージを与える可能性が高いので、早期発見がとても重要となります。",10,190)
            doc.text("木材同士の接合部に用いられている金物が使われているか、木材に過度の欠き込みがないかも確認", 10,195)
            doc.text("しています。", 10,200)

            doc.text("●なぜ、床下の検査が必要なのか", 10, 215)
            doc.text("床下は非常に重要な検査箇所です。床下の配管で漏水が起こっていてそのまま放置していると、",10,225)
            doc.text("土台などの木部に腐朽や蟻害が発生し、建物構造体へ大きなダメージを与える可能性があります。",10,230)


            doc.text("●なぜ、設備の検査が必要なのか", 10,245)
            doc.text("給水量不足が起こると不便を感じる場合があります。その他、漏水に気付かずに暮らしていると、", 10,255)
            doc.text("床・壁・天井などの下地材に大きなダメージを与える場合があるので、見える範囲内であっても、",10,260)
            doc.text("漏水があるか確認することは重要です。換気扇に関しては、１箇所ずつ確認を行うことで、動作",10,265)
            doc.text("不良や異音などがわかります。", 10, 270)
           

            doc.addPage()

          // ここ直す

            doc.text("建物外部（１）検査報告", 80, 30)

            doc.autoTable({
              margin: { top: 40 },
              head: [['部分', '部位・部材・事象 ', '番号', "検査項目", "あり", "済"]],
              body: tatemonogaibu1,
              styles: {font: 'mplus', fontStyle: 'normal', fontSize: 9}
            })

            doc.addPage()
            
            doc.text("建物外部（2）検査報告", 80, 30)

            doc.autoTable({
              margin: { top: 40 },
              head: [['部分', '部位・部材・事象 ', '番号', "検査項目", "あり", "済"]],
              body: tatemonogaibu2,
              styles: {font: 'mplus', fontStyle: 'normal', fontSize: 9}
            })
            
            doc.addPage()

            doc.text("建物周囲　検査報告", 85, 30)

            doc.autoTable({
              margin: { top: 40 },
              head: [['部分', '部位・部材・事象 ', '番号', "検査項目", "あり", "済"]],
              body: tatemonoshuui,
              styles: {font: 'mplus', fontStyle: 'normal', fontSize: 9}
            })

            doc.addPage()

            doc.text("室内（１）　検査報告", 85, 30)

            doc.autoTable({
              margin: { top: 40 },
              head: [['部分', '部位・部材・事象 ', '番号', "検査項目", "あり", "済"]],
              body: shitsunai1,
              styles: {font: 'mplus', fontStyle: 'normal', fontSize: 9}
            })
            
            doc.addPage()

            doc.text("室内（２）　検査報告", 85, 30)

            doc.autoTable({
              margin: { top: 40 },
              head: [['部分', '部位・部材・事象 ', '番号', "検査項目", "あり", "済"]],
              body: shitsunai2,
              styles: {font: 'mplus', fontStyle: 'normal', fontSize: 9}
            })

            doc.addPage()

            doc.text("屋根裏　検査報告", 85, 30)

            doc.autoTable({
              margin: { top: 40 },
              head: [['部分', '部位・部材・事象 ', '番号', "検査項目", "あり", "済"]],
              body: yaneura,
              styles: {font: 'mplus', fontStyle: 'normal', fontSize: 8}
            })

            doc.addPage()

            doc.text("床下　検査報告", 85, 30)

            doc.autoTable({
              margin: { top: 40 },
              head: [['部分', '部位・部材・事象 ', '番号', "検査項目", "あり", "済"]],
              body: yukashita,
              styles: {font: 'mplus', fontStyle: 'normal', fontSize: 9}
            })

            doc.addPage()

            doc.text("設備　検査報告", 90, 30)

            doc.autoTable({
              margin: { top: 40 },
              head: [['部分', '部位・部材・事象 ', '番号', "検査項目", "あり", "済"]],
              body: setsubi,
              styles: {font: 'mplus', fontStyle: 'normal', fontSize: 9}
            })

            
            doc.addPage()

            doc.text("調査画像（１）", 90, 30)
            console.log(testBlob, "testBlob")
            doc.text("全景", 10, 40)
            if(testBlob.hasOwnProperty("zenkei")){
            testBlob?.zenkei[0]?.imgData && doc.addImage(URL.createObjectURL(testBlob.zenkei[0]?.imgData), 'JPEG', 10, 45, width/5, 0)
            testBlob?.zenkei[1]?.imgData && doc.addImage(URL.createObjectURL(testBlob.zenkei[1]?.imgData), 'JPEG', 15+width/5, 45, width/5, 0) 
            testBlob?.zenkei[2]?.imgData && doc.addImage(URL.createObjectURL(testBlob.zenkei[2]?.imgData), 'JPEG', 20+width/5*2, 45, width/5, 0)
            testBlob?.zenkei[3]?.imgData && doc.addImage(URL.createObjectURL(testBlob.zenkei[3]?.imgData), 'JPEG', 25+width/5*3, 45, width/5, 0)
            }

            doc.text("屋根", 10, 85)
            if(testBlob.hasOwnProperty("yane")){
            testBlob?.yane[0]?.imgData && doc.addImage(URL.createObjectURL(testBlob.yane[0]?.imgData), 'JPEG', 10, 90, width/5, 0)
            testBlob?.yane[1]?.imgData && doc.addImage(URL.createObjectURL(testBlob.yane[1]?.imgData), 'JPEG', 15+width/5, 90, width/5, 0) 
            testBlob?.yane[2]?.imgData && doc.addImage(URL.createObjectURL(testBlob.yane[2]?.imgData), 'JPEG', 20+width/5*2, 90, width/5, 0)
            testBlob?.yane[3]?.imgData && doc.addImage(URL.createObjectURL(testBlob.yane[3]?.imgData), 'JPEG', 25+width/5*3, 90, width/5, 0)
            }

            doc.text("外周部", 10, 130)
            if(testBlob.hasOwnProperty("gaishubu")){
            testBlob?.gaishubu[0]?.imgData && doc.addImage(URL.createObjectURL(testBlob.gaishubu[0]?.imgData), 'JPEG', 10, 135, width/5, 0)
            testBlob?.gaishubu[1]?.imgData && doc.addImage(URL.createObjectURL(testBlob.gaishubu[1]?.imgData), 'JPEG', 15+width/5, 135, width/5, 0)
            testBlob?.gaishubu[2]?.imgData && doc.addImage(URL.createObjectURL(testBlob.gaishubu[2]?.imgData), 'JPEG', 20+width/5*2, 135, width/5, 0)
            testBlob?.gaishubu[3]?.imgData && doc.addImage(URL.createObjectURL(testBlob.gaishubu[3]?.imgData), 'JPEG', 25+width/5*3, 135, width/5, 0)
            }

            doc.text("基礎", 10, 175)
            if(testBlob.hasOwnProperty("kiso")){
            testBlob?.kiso[0]?.imgData && doc.addImage(URL.createObjectURL(testBlob.kiso[0]?.imgData), 'JPEG', 10, 180, width/5, 0)
            testBlob?.kiso[1]?.imgData && doc.addImage(URL.createObjectURL(testBlob.kiso[1]?.imgData), 'JPEG', 15+width/5, 180, width/5, 0)
            testBlob?.kiso[2]?.imgData && doc.addImage(URL.createObjectURL(testBlob.kiso[2]?.imgData), 'JPEG', 20+width/5*2, 180, width/5, 0)
            testBlob?.kiso[3]?.imgData && doc.addImage(URL.createObjectURL(testBlob.kiso[3]?.imgData), 'JPEG', 25+width/5*3, 180, width/5, 0)
            }

            doc.text("外壁" , 10, 225)
            if(testBlob.hasOwnProperty("gaiheki")){
            testBlob?.gaiheki[0]?.imgData && doc.addImage(URL.createObjectURL(testBlob.gaiheki[0]?.imgData), 'JPEG', 10, 230, width/5, 0)
            testBlob?.gaiheki[1]?.imgData && doc.addImage(URL.createObjectURL(testBlob.gaiheki[1]?.imgData), 'JPEG', 15+width/5, 230, width/5, 0)
            testBlob?.gaiheki[2]?.imgData && doc.addImage(URL.createObjectURL(testBlob.gaiheki[2]?.imgData), 'JPEG', 20+width/5*2, 230, width/5, 0)
            testBlob?.gaiheki[3]?.imgData && doc.addImage(URL.createObjectURL(testBlob.gaiheki[3]?.imgData), 'JPEG', 25+width/5*3, 230, width/5, 0)
            }
  
            doc.addPage()

            doc.text("調査画像（２）", 90, 30)

            doc.text("閉口部", 10, 40)
            if(testBlob.hasOwnProperty("heikoubu")){
            testBlob?.heikoubu[0]?.imgData && doc.addImage(URL.createObjectURL(testBlob.heikoubu[0]?.imgData), 'JPEG', 10, 45, width/5, 0)
            testBlob?.heikoubu[1]?.imgData && doc.addImage(URL.createObjectURL(testBlob.heikoubu[1]?.imgData), 'JPEG', 15+width/5, 45, width/5, 0) 
            testBlob?.heikoubu[2]?.imgData && doc.addImage(URL.createObjectURL(testBlob.heikoubu[2]?.imgData), 'JPEG', 20+width/5*2, 45, width/5, 0)
            testBlob?.heikoubu[3]?.imgData && doc.addImage(URL.createObjectURL(testBlob.heikoubu[3]?.imgData), 'JPEG', 25+width/5*3, 45, width/5, 0)
            }

            doc.text("軒まわり", 10, 85)
            if(testBlob.hasOwnProperty("nokimawari")){
            testBlob?.nokimawari[0]?.imgData && doc.addImage(URL.createObjectURL(testBlob.nokimawari[0]?.imgData), 'JPEG', 10, 90, width/5, 0)
            testBlob?.nokimawari[1]?.imgData && doc.addImage(URL.createObjectURL(testBlob.nokimawari[1]?.imgData), 'JPEG', 15+width/5, 90, width/5, 0) 
            testBlob?.nokimawari[2]?.imgData && doc.addImage(URL.createObjectURL(testBlob.nokimawari[2]?.imgData), 'JPEG', 20+width/5*2, 90, width/5, 0)
            testBlob?.nokimawari[3]?.imgData && doc.addImage(URL.createObjectURL(testBlob.nokimawari[3]?.imgData), 'JPEG', 25+width/5*3, 90, width/5, 0)
            }

            doc.text("隣地境界", 10, 130)
            if(testBlob.hasOwnProperty("rinchikyoukai")){
            testBlob?.rinchikyoukai[0]?.imgData && doc.addImage(URL.createObjectURL(testBlob.rinchikyoukai[0]?.imgData), 'JPEG', 10, 135, width/5, 0)
            testBlob?.rinchikyoukai[1]?.imgData && doc.addImage(URL.createObjectURL(testBlob.rinchikyoukai[1]?.imgData), 'JPEG', 15+width/5, 135, width/5, 0)
            testBlob?.rinchikyoukai[2]?.imgData && doc.addImage(URL.createObjectURL(testBlob.rinchikyoukai[2]?.imgData), 'JPEG', 20+width/5*2, 135, width/5, 0)
            testBlob?.rinchikyoukai[3]?.imgData && doc.addImage(URL.createObjectURL(testBlob.rinchikyoukai[3]?.imgData), 'JPEG', 25+width/5*3, 135, width/5, 0)
            }

            doc.text("機器類", 10, 175)
            if(testBlob.hasOwnProperty("kikirui")){
            testBlob?.kikirui[0]?.imgData && doc.addImage(URL.createObjectURL(testBlob.kikirui[0]?.imgData), 'JPEG', 10, 180, width/5, 0)
            testBlob?.kikirui[1]?.imgData && doc.addImage(URL.createObjectURL(testBlob.kikirui[1]?.imgData), 'JPEG', 15+width/5, 180, width/5, 0)
            testBlob?.kikirui[2]?.imgData && doc.addImage(URL.createObjectURL(testBlob.kikirui[2]?.imgData), 'JPEG', 20+width/5*2, 180, width/5, 0)
            testBlob?.kikirui[3]?.imgData && doc.addImage(URL.createObjectURL(testBlob.kikirui[3]?.imgData), 'JPEG', 25+width/5*3, 180, width/5, 0)
            }

            doc.text("測定調査", 10, 220)
            if(testBlob.hasOwnProperty("sokuteichousa")){
            testBlob?.sokuteichousa[0]?.imgData && doc.addImage(URL.createObjectURL(testBlob.sokuteichousa[0]?.imgData), 'JPEG', 10, 225, width/5, 0)
            testBlob?.sokuteichousa[1]?.imgData && doc.addImage(URL.createObjectURL(testBlob.sokuteichousa[1]?.imgData), 'JPEG', 15+width/5, 225, width/5, 0)
            testBlob?.sokuteichousa[2]?.imgData && doc.addImage(URL.createObjectURL(testBlob.sokuteichousa[2]?.imgData), 'JPEG', 20+width/5*2, 225, width/5, 0)
            testBlob?.sokuteichousa[3]?.imgData && doc.addImage(URL.createObjectURL(testBlob.sokuteichousa[3]?.imgData), 'JPEG', 25+width/5*3, 225, width/5, 0)
            }

            if(Object.keys(customData).length>0){
              console.log(customData, "BEFORE FILTER")
              if(testBlob?.sokuteichousa.length==6){
                customData[0]=[{placeName: "", imgData:testBlob?.sokuteichousa[4]?.imgData}, {placeName: "測定調査", imgData:testBlob?.sokuteichousa[5]?.imgData}]
              } else if(testBlob?.sokuteichousa.length==5){
                customData[0]=[{placeName: "", imgData:testBlob?.sokuteichousa[4]?.imgData}]
              }

              // 5枚以上の写真の場合PDFで複数行へ、また写真が0枚の場合上に詰める
              let data = []
              for(let key in customData){
                if(customData[key].length>0 && customData[key].length<=4){
                  data.push(customData[key])
                } else if(customData[key].length>4){
                  // 前半部分
                  data.push([customData[key][0], customData[key][1], customData[key][2], customData[key][3]])
                  // 後半部分
                  data.push(customData[key].slice(4))
                }
              }


            //customData = Object.values(customData).filter((data)=>data.length>0)
            console.log(data, "FILTER AND PROCEEdd")

            let imgPage = 2
            {Object.keys(data).forEach((key,i)=>{
              if(i%5===0){
                doc.addPage()
                imgPage += 1
                doc.text(`調査画像(${imgPage})`, 90, 30) 
              }
              if(data[key].length) doc.text(data[key][0]?.placeName, 10, 40+(45*(i%5)))
              data[key][0]?.imgData && doc.addImage(URL.createObjectURL(data[key][0]?.imgData), 'JPEG', 10, 45*((i%5)+1), width/5, 0)
              data[key][1]?.imgData && doc.addImage(URL.createObjectURL(data[key][1]?.imgData), 'JPEG', 15+width/5, 45*((i%5)+1), width/5, 0) 
              data[key][2]?.imgData && doc.addImage(URL.createObjectURL(data[key][2]?.imgData), 'JPEG', 20+width/5*2, 45*((i%5)+1), width/5, 0)
              data[key][3]?.imgData && doc.addImage(URL.createObjectURL(data[key][3]?.imgData), 'JPEG', 25+width/5*3, 45*((i%5)+1), width/5, 0)
            })}

          }


          doc.addPage()
          doc.text("不具合箇所", 90, 30)
          console.log(pointingImg, "pointingImg")
          pointingImg && doc.addImage(URL.createObjectURL(pointingImg), 'PNG', 25, 40, width-50, height-70); 


            {hasProblemListsResult.forEach((problem, i)=>{
              if(i%4===0){
              doc.addPage()

              doc.text("不具合画像", 90, 30)
              
              doc.text( problem?.title, 10, 45)
              doc.text( problem?.subtitle, 10, 50)
              if(problem?.checItem.length>=19){
                doc.setFontSize(9);
                doc.text( problem?.pointingId+problem?.branchId+" | "+problem?.checItem, 10, 55)
                doc.setFontSize(12);
              }else{
                doc.text( problem?.pointingId+problem?.branchId+" | "+problem?.checItem, 10, 55)
              }
              doc.addImage(URL.createObjectURL(problem?.imgData), 'JPEG', 10, 60, width/2-10, 0)
             

              var lines =doc.splitTextToSize(problem?.comment, width/2-10);
	            doc.text(10,140,lines);
              
              } else if(i%4===1){
                doc.text(problem?.title,  width/2+5, 45)
                doc.text(problem?.subtitle,  width/2+5, 50)
                if(problem?.checItem.length>=19){
                  doc.setFontSize(9);
                  doc.text( problem?.pointingId+problem?.branchId+" | "+problem?.checItem,  width/2+5, 55)
                  doc.setFontSize(12);
                }else{
                  doc.text( problem?.pointingId+problem?.branchId+" | "+problem?.checItem,  width/2+5, 55)
                }
                doc.addImage(URL.createObjectURL(problem?.imgData), 'JPEG', width/2+5, 60, width/2-10, 0)
                   
                var lines =doc.splitTextToSize(problem?.comment, width/2-10);
	              doc.text( width/2+5,140,lines);
                
              }  else if(i%4===2){
                doc.text(problem?.title,  10, 170)
                doc.text(problem?.subtitle,  10, 175)
                if(problem?.checItem.length>=19){
                  doc.setFontSize(9);
                  doc.text( problem?.pointingId+problem?.branchId+" | "+problem?.checItem,  10, 180)
                  doc.setFontSize(12);
                }else{
                  doc.text( problem?.pointingId+problem?.branchId+" | "+problem?.checItem,  10, 180)
                }
                doc.addImage(URL.createObjectURL(problem?.imgData), 'JPEG', 10, 185, width/2-10, 0)
         
                var lines =doc.splitTextToSize(problem?.comment, width/2-10);
	              doc.text(10,265,lines);
                
              }  else if(i%4===3){
                doc.text(problem?.title,  width/2+5, 170)
                doc.text(problem?.subtitle,  width/2+5, 175)
                if(problem?.checItem.length>=19){
                  doc.setFontSize(9);
                  doc.text( problem?.pointingId+problem?.branchId+" | "+problem?.checItem,  width/2+5, 180)
                  doc.setFontSize(12);
                }else{
                  doc.text( problem?.pointingId+problem?.branchId+" | "+problem?.checItem,  width/2+5, 180)
                }
                doc.addImage(URL.createObjectURL(problem?.imgData), 'JPEG', width/2+5, 185, width/2-10, 0)

                var lines =doc.splitTextToSize(problem?.comment, width/2-10);
	              doc.text( width/2+5,265,lines);
              }
            })}

            doc.addPage()

            doc.text("壁・床レベル", 90, 15)
            levelPoitingImg && doc.addImage(URL.createObjectURL(levelPoitingImg), 'PNG', 25, 22, width-50, height-38); 

            insertFooter()

            doc.save("報告書.pdf")  
  
    }

   if(type==="satsuei"){
     return (
       <>
       <div>
       {problemCheckList.map((problemCheckItem)=>{
         return <button style={style} onClick={()=>{
           setType("problem")
           setPlace(problemCheckItem.id)
         }}>{problemCheckItem.name}</button>
       })}
       </div> 


      {loadingFlag ? <ReactLoading type="bubbles" color="#A9A9A9" height={"5%"} width={"80%"} />:  
      <>
      <div style={{textAlign: "right",marginTop: 30}}>
      <button style={style} onClick ={ async ()=> {
      setType("satsuei")
      setLoadingFlag(true)
      //const satsueiList = await storageListAll()
      await getSatsueiPictures()
 
      setLoadingFlag(false)

        }} >撮影写真一覧</button>  
      <button style={style} onClick={async ()=>{
        setType("problem")
        setPlace("all")
        //await getProblemsData()
        }}>不具合一覧</button>
      <button style={style} onClick={async () => {
        setLoadingFlag(true)
        const satsueiList = await storageListAll()
        const {data, hasProblemListsResult } = await getAllData()
        const customData = await getCustomPlaceImg()
        const testBlob = await getImageTest(satsueiList)
        await pdfPrint(data, hasProblemListsResult, testBlob, customData)
        setLoadingFlag(false)
      }}>報告書作成</button>
      </div>

      {satsueiPlaceList.map((satueiPlace)=>{
        return (
          <>
            <p>{satueiPlace.name}</p>
            {satsueiPictures.hasOwnProperty(satueiPlace.id) && satsueiPictures[satueiPlace.id].length > 0 ?
            <div style={{display: "flex", flexWrap: "wrap"}}> 
              {satsueiPictures[satueiPlace.id].map((satuelPicture)=>{
                return(
                  <><img src={satuelPicture.imgUrl} width="250px" style={{marginRight:9, marginBottom: 9}}></img></>
                )
              })}
            </div>:
            <p>写真はありません。</p>
              
            }
          </>
        )
      })}

      {customPlaces.map((customPlace)=>{
        return (
          <>
          <p>{customPlace.placeName}</p>
          {customPlace.hasOwnProperty("imageUrl") && customPlace.imageUrl.length>0 ?
          <div style={{display: "flex", flexWrap: "wrap"}}> 
          {customPlace.imageUrl.map((imageUrl)=>{
            return(
              <><img src={imageUrl}  width="250px" style={{marginRight:9, marginBottom: 9}}></img></>
            )
          })}
       
        </div>: <p>写真はありません。</p>
         }
          </>
        )
      })}
      </>}
     </>
     ) 
   } else if("problem"){
   return(
    <div >
    <div>
      {problemCheckList.map((problemCheckItem)=>{
        return <button style={style} onClick={()=>{
          setType("problem")
          setPlace(problemCheckItem.id)
        }}>{problemCheckItem.name}</button>
      })}
    </div>  
    {loadingFlag ? <ReactLoading type="bubbles" color="#A9A9A9" height={"5%"} width={"80%"} />: 
    <>
    {isEditing ?
     place !== "all" && 
     <div style={{textAlign: "right"}}><button style={style} onClick = {async ()=>{
      await updateInspectionData()
      setIsEditing(false)
      setLoadingFlag(true)
      await delay(3)
      setLoadingFlag(false)
      getData()
      }}>保存</button></div>: 
      place !== "all" && 
      <div style={{textAlign: "right"}}> 
      {/*<button onClick={async ()=>{await getAllData() }}>テスト</button>*/}
      <button style={style} onClick ={()=> setIsEditing(true)}>編集</button>
      </div> }

      <div style={{textAlign: "right",marginTop: 30}}>
      <button style={style} onClick ={ async ()=> {
      setType("satsuei")
      setLoadingFlag(true)
      //const satsueiList = await storageListAll()
      await getSatsueiPictures()
 
      setLoadingFlag(false)

        }} >撮影写真一覧</button>  
      <button style={style} onClick={async ()=>{
        setType("problem")
        setPlace("all")
        //await getProblemsData()
        }}>不具合一覧</button>
      <button style={style} onClick={async () => {
        setLoadingFlag(true)
        const satsueiList = await storageListAll()
        const {data, hasProblemListsResult } = await getAllData()
        const customData = await getCustomPlaceImg()
        const testBlob = await getImageTest(satsueiList)
        await pdfPrint(data, hasProblemListsResult, testBlob, customData)
        setLoadingFlag(false)
      }}>報告書作成</button>
      </div>

    {data.length > 0 ? data.map((com)=>{
      return(
      <>
      {isEditing ? 
      <Card style={{marginTop: 20}} key={com.id}>
      <CardContent>
        <div style={{fontSize: "4px", color: "#C0C0C0"}}>検査項目</div>
        <div>{com.title}</div>
        <div>{com.subtitle}</div>
        <div>{com.checItem}</div>
        <div style={{fontSize: "4px", color: "#C0C0C0", marginTop: 10}}>回答</div>
        <TextField name="hasProblem" id={com.id} onChange={handleInputChange} defaultValue={com.hasProblem} variant="outlined" />   
        <br/>
        {com["problems"]?.length > 0 && 
            com["problems"].map((c, i)=>{
              return (
                <>
                  <img style={{marginTop: 20}} src={c.imgUrl} width="300px"/>
                  <TextField name={`comment${i}`} id={com.id}  fullWidth onChange={handleInputChange} defaultValue={c.comment} variant="outlined" />
                </>
              )
        })}
        </CardContent>  
      </Card>
   
      :
      <Card style={{marginTop: 20}} key={com.id}>
      <CardContent >
        <div style={{fontSize: "4px", color: "#C0C0C0"}}>検査項目</div>

        <div>{com.title}</div>
            <div>{com.subtitle}</div>
            <div>{com.checItem}</div>
            <div style={{fontSize: "4px", color: "#C0C0C0", marginTop: 10}}>回答</div>
            <div>{com.hasProblem}</div>
            {com["problems"]?.length > 0 && 
            com["problems"].map((c)=>{
              return (
                <>
                  <img style={{marginTop: 20}} src={c.imgUrl} width="300px"/>
                  <p>{c.comment}</p>
                </>
              )
            }) }
        </CardContent>  
      </Card>
      }
      </>  
      )
      
    }):<>検査が未完了です。</>}</>} 
    
  </div>
   ) }
}

export default PDF